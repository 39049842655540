@import url(/src/scss/partials/vars.scss);
@import url(/src/scss/partials/reset.scss);
@import url(/src/scss/partials/font.scss);
@import url(/src/scss/partials/components.scss);
 
// Build the layout
@import url(/src/scss/partials/nav.scss);
@import url(/src/scss/partials/layout.scss); 
@import url(/src/scss/partials/body.scss);
@import url(/src/scss/partials/guitar.scss);
@import url(/src/scss/partials/modal.scss);
@import url(/src/scss/partials/tooltip.scss);
 
body {
    color:var(--col-txt);
    font-family: "basic-sans",sans-serif;
    font-weight: var(--global-fontsize);
    letter-spacing: var(--global-letterspacing);
}