@import "https://p.typekit.net/p.css?s=1&k=frx2auv&ht=tk&f=35474.35476.35478&a=193972351&app=typekit&e=css";
:root {
  --global-fontsize: .875rem;
  --global-letterspacing: .04rem;
  --var: #fff;
  --layout-nav-height: 100px;
  --layout-footer-height: 40px;
  --layout-pagewidth: 1400px;
  --layout-gap: 8px;
  --layout-guitargrid-top: 84px;
  --layout-guitargrid-bottom: var(--layout-guitargrid-top);
  --layout-guitargrid-middle: 1fr;
  --fontweight-light: 600;
  --fontweight-regular: 700;
  --fontweight-bold: 900;
  --fontweight-megabold: 900;
  --component-fontsize: .875rem;
  --component-buttonheight: 40px;
  --component-filterheight: 60px;
  --col-1: #1c2125;
  --col-2: #1c2125;
  --col-3: #292f3a;
  --col-3--hover: #3a414f;
  --col-accent: #7a5cff;
  --col-note-accent: #f61a5d;
  --col-note-root: orange;
  --col-txt: #fff;
  --col-guitar-string: #5a5d67;
  --col-guitar-fret: #b8bcbe;
  --col-guitar-note-border: var(--col-guitar-fret);
  --col-guitar-nut: #fff;
  --col-comingsoon: #d14e7f;
}

:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

figure {
  margin: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-block-size: 100%;
  max-inline-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/fa5d28/00000000000000007735abe8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/c68f64/00000000000000007735abe6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}

@font-face {
  font-family: basic-sans;
  src: url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/721f9c/00000000000000007735abf2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-basic-sans {
  font-family: basic-sans, sans-serif;
}



.nav {
  background-color: var(--col-2);
  align-items: center;
  display: flex;
}

.nav__wrap {
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 90%;
  margin: 0 auto;
  display: flex;
}

.nav__logowrap {
  height: 75%;
  display: block;
}

.nav__logo {
  fill: #fff;
  height: 100%;
  display: block;
}

.nav__actionbtn {
  --iconsize: 20px;
  background-color: var(--col-3);
  font-weight: var(--fontweight-regular);
  font-size: var(--component-fontsize);
  height: var(--component-buttonheight);
  min-width: var(--component-buttonheight);
  outline-offset: 1px;
  --component-fontsize: .875rem;
  border-radius: 100px;
  outline: 2px solid #0000;
  align-items: center;
  gap: 6px;
  height: 38px;
  padding: 0 16px;
  line-height: 0;
  display: flex;
}

.nav__actionbtn:hover {
  outline-color: var(--col-accent);
  cursor: pointer;
}

.nav__actionbtn-lbl {
  line-height: var(--component-fontsize);
  display: block;
}

.nav__actionbtn-icon {
  fill: #fff;
  width: var(--iconsize);
  height: var(--iconsize);
  order: -1;
}

.nav__actions {
  gap: var(--layout-gap);
  align-items: center;
  display: flex;
}

.nav__comingsoon {
  align-items: center;
  gap: var(--layout-gap);
  border: 2px solid var(--col-comingsoon);
  border-radius: 40px;
  padding: 12px;
  display: flex;
  position: relative;
}

.nav__comingsoon-txt {
  color: #fff;
  background-color: var(--col-comingsoon);
  border-radius: 10px;
  align-items: center;
  height: 18px;
  padding: 0 8px;
  font-size: .625rem;
  font-weight: 700;
  line-height: 0;
  display: flex;
  position: absolute;
  top: -13px;
  right: 50%;
  transform: translateX(50%);
}

.footer {
  background-color: var(--col-2);
  font-size: .75rem;
  font-weight: var(--fontweight-bold);
  align-items: center;
  display: flex;
}

.footer__wrap {
  justify-content: flex-end;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  display: flex;
}

.footer__version {
  align-items: center;
  gap: 8px;
  margin-right: auto;
  display: flex;
}

.footer__version-lbl {
  background-color: var(--col-3);
  border-radius: 10px;
  margin-right: auto;
  padding: 4px 10px;
}

.footer__version-feedback {
  font-weight: var(--fontweight-regular);
  text-underline-offset: 2px;
  text-decoration: underline;
}

.footer__version-feedback:hover {
  background-color: var(--col-note-accent);
  outline: 4px solid var(--col-note-accent);
  cursor: pointer;
}

.footer__link {
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
}

.footer__linklbl {
  font-weight: var(--fontweight-regular);
}

.footer__linkface {
  border-radius: 30px;
  width: 26px;
  height: 26px;
}

.footer__link:hover {
  cursor: pointer;
}

.footer__link:hover .footer__linklbl {
  text-underline-offset: 2px;
  text-decoration: underline;
}

#app {
  grid-template-rows: var(--layout-nav-height) 1fr var(--layout-footer-height);
  height: 100vh;
  display: grid;
}

.main {
  border-top: 1px solid var(--col-3);
  background-color: var(--col-1);
  border-bottom: 1px solid var(--col-3);
  grid-template-rows: var(--layout-guitargrid-top) 1fr var(--layout-guitargrid-bottom);
  display: grid;
}

.main__topline, .main__bottomline {
  justify-content: space-between;
  align-items: center;
  gap: var(--layout-gap);
  display: flex;
}

.main__middle {
  align-items: center;
  display: flex;
}

.main {
  padding: 0 2.5%;
  position: relative;
}

.scalefilter {
  align-items: center;
  gap: calc(var(--layout-gap) * 2);
  font-weight: var(--fontweight-bold);
  font-size: var(--component-fontsize);
  height: var(--component-filterheight);
  border: 1px solid var(--col-3);
  background-color: #0000;
  border-radius: 100px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px 0 20px;
  line-height: 0;
  display: flex;
}

.scalefilter__lbl {
  font-weight: var(--fontweight-regular);
  line-height: normal;
}

.scalefilter__actions {
  align-items: center;
  gap: calc(var(--layout-gap) / 1.5);
  display: flex;
}

.scalefilter__btn {
  --iconsize: 20px;
  background-color: var(--col-3);
  font-weight: var(--fontweight-regular);
  font-size: var(--component-fontsize);
  height: var(--component-buttonheight);
  min-width: var(--component-buttonheight);
  outline-offset: 1px;
  border-radius: 100px;
  outline: 2px solid #0000;
  align-items: center;
  gap: 6px;
  padding: 0 16px;
  line-height: 0;
  display: flex;
}

.scalefilter__btn:hover {
  outline-color: var(--col-accent);
  cursor: pointer;
}

.scalefilter__btn-lbl {
  line-height: var(--component-fontsize);
  display: block;
}

.scalefilter__btn-icon {
  fill: #fff;
  width: var(--iconsize);
  height: var(--iconsize);
  order: -1;
}

.scalefilter__btn:hover {
  background-color: var(--col-3--hover);
}

.scalefilter__btn.active {
  background-color: var(--col-accent);
}

.scalefilter__btn.active:hover {
  outline-color: var(--col-accent);
}

.scalefilter__select {
  --iconsize: 20px;
  background-color: var(--col-3);
  font-weight: var(--fontweight-regular);
  font-size: var(--component-fontsize);
  height: var(--component-buttonheight);
  min-width: var(--component-buttonheight);
  outline-offset: 1px;
  text-align: center;
  border-radius: 100px;
  outline: 2px solid #0000;
  align-items: center;
  gap: 6px;
  width: 140px;
  padding: 0 16px;
  line-height: 1rem;
  display: flex;
}

.scalefilter__select:hover {
  outline-color: var(--col-accent);
  cursor: pointer;
}

.scalefilter__select-lbl {
  line-height: var(--component-fontsize);
  display: block;
}

.scalefilter__select-icon {
  fill: #fff;
  width: var(--iconsize);
  height: var(--iconsize);
  order: -1;
}

.scalefilter--disabled {
  pointer-events: none;
  opacity: .4;
}

.betabutton {
  z-index: 11;
  font-weight: var(--fontweight-bold);
  background-color: var(--col-comingsoon);
  border-radius: 30px;
  padding: 8px 20px;
  position: absolute;
  top: 10px;
  right: 50%;
  transform: translate(50%);
}

.betabutton:hover {
  background-color: var(--col-accent);
  cursor: pointer;
}

.guitar {
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  display: grid;
}

.guitar__nut {
  border-right: 5px solid var(--col-guitar-nut);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  height: 100%;
  display: grid;
}

.guitar__nutnote {
  font-weight: var(--fontweight-megabold);
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 60px;
  padding: 0;
  display: flex;
}

.guitar__fretboard {
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  width: 100%;
  height: 100%;
  display: grid;
}

.guitar__fretmarkers {
  grid-column: -1 / 1;
  grid-template-columns: repeat(12, 1fr);
  margin-left: 52px;
  display: grid;
}

.guitar__fretmarker {
  font-weight: var(--fontweight-megabold);
  justify-content: center;
  align-items: center;
  height: 80px;
  display: flex;
}

.guitar__string {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
}

.guitar__string:before {
  content: "";
  background-color: var(--col-guitar-string);
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.guitar__string .guitar__fret {
  border-right: 3px solid var(--col-guitar-fret);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.guitar__string .guitar__note {
  outline: 4px solid var(--col-1);
  border: 3px solid var(--col-guitar-note-border);
  font-weight: var(--fontweight-megabold);
  background-color: var(--col-1);
  cursor: pointer;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 1.25rem;
  display: flex;
}

.guitar__string .guitar__note:hover {
  border-color: #fff;
}

.guitar__string .guitar__note--active {
  background-color: var(--col-note-accent);
  border-color: var(--col-note-accent);
}

.guitar__string .guitar__note--active:hover {
  border-color: var(--col-note-accent);
}

.guitar__string .guitar__note--matchinghover {
  outline-offset: -1px;
  border-color: #e4d00a !important;
  outline: 2px solid #e4d00a !important;
}

.guitar__string .guitar__note--sharpflat {
  font-size: .75rem;
}

.guitar__string .guitar__note--root {
  border-color: var(--col-note-root);
}

.guitar__string .guitar__note--root:hover {
  border-color: var(--col-note-root) !important;
}

.modal {
  background-color: var(--col-1);
  color: #fff;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  max-width: 600px;
  padding: 32px;
}

.modal__content {
  text-align: center;
  gap: 16px;
  display: grid;
}

.modal__close {
  background-color: var(--col-note-accent);
  cursor: pointer;
  border-radius: 4px;
  margin: 0 auto;
  padding: 6px;
  display: flex;
}

::backdrop {
  opacity: .75;
  background-image: linear-gradient(45deg, #f0f, #639, #1e90ff, #00f);
}

.tooltip {
  color: #fff;
  font-weight: var(--fontweight-regular);
  z-index: 9999;
  background: #0f1821;
  border-radius: 4px;
  width: max-content;
  padding: 6px 8px;
  font-size: .7rem;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

body {
  color: var(--col-txt);
  font-family: basic-sans, sans-serif;
  font-weight: var(--global-fontsize);
  letter-spacing: var(--global-letterspacing);
}

/*# sourceMappingURL=index.dfa9775e.css.map */
