@use "./components" as *;

.nav {
    background-color:var(--col-2);
    display: flex;
    align-items: center;

    &__wrap {
        height:90%;
        display: flex;
        align-items: center; 
        justify-content: space-between;
        width:95%;
        margin:0px auto;
    }

    &__logowrap {
         height:75%;
        display: block;
    }

    &__logo {
        height:100%;
        display: block;
        fill: #fff;
    }

    &__actionbtn {
        @include roundbtn;
        --component-fontsize:0.875rem;
        height:38px;
    }

    &__actions {
        display: flex;
        gap:var(--layout-gap);
        align-items: center;
    }

    &__comingsoon {
        display: flex;
        align-items: center;
        gap:var(--layout-gap);
        border:2px solid var(--col-comingsoon);
        border-radius: 40px;
        padding:12px;
        position: relative;

        &-txt {
            color: #fff;
            background-color: var(--col-comingsoon);
            border-radius: 10px;
            padding: 0px 8px;
            position: absolute;
            top: -13px;
            right: 50%;
            transform: translateX(50%);
            font-size: 0.625rem;
            font-weight: 700;
            display: flex;
            line-height: 0;
            align-items: center;
            height: 18px;
        }

    }

}



.footer {
    background-color:var(--col-2);
    font-size: 0.75rem;
    font-weight: var(--fontweight-bold);
    display: flex;
    align-items: center;

    &__wrap { 
        width:95%;
        margin:0px auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__version {
        display: flex;
        align-items: center;
        margin-right:auto;
        gap:8px;

        &-lbl {
            background-color: var(--col-3);
            padding:4px 10px;
            border-radius: 10px;
            margin-right:auto;
        }

        &-feedback {
            font-weight: var(--fontweight-regular);
            text-decoration: underline;
            text-underline-offset: 2px;

            &:hover {
                background-color: var(--col-note-accent);
                outline:4px solid var(--col-note-accent);
                cursor: pointer;
            }

        }

    }



    &__face {

    }

    &__link {
        $self:&;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap:12px;

        &lbl {
            font-weight: var(--fontweight-regular);
        }

        &face {
            width:26px;
            height:26px;
            border-radius: 30px;
        }

        &:hover {
            cursor: pointer;

            #{$self}lbl {
                text-decoration: underline;
                text-underline-offset: 2px;
            }

        }

    }

}