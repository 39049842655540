.guitar {
    $self:&;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    
    width:100%;

    &__nut {
        border-right:5px solid var(--col-guitar-nut);
        height:100%;
        display: grid;
        align-items: center;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &__nutnote {
            width:52px;// var up
            height:60px; // var up
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 0px;
            font-weight: var(--fontweight-megabold);
        
    }

    &__fretboard {
        width:100%;
        height:100%;
        align-items: center;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &__fretmarkers {
        grid-column: -1/1;
        margin-left:52px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }

    &__fretmarker {
        display: flex;
        align-items: center;
        justify-content: center;
        height:80px;
        font-weight: var(--fontweight-megabold);
    }

    &__string { 
        width:100%;
        height:100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        position: relative;
        

        &:before {
            content: "";
            position: absolute;
            top: 0%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--col-guitar-string);
            top: 50%;
        }


        #{$self}__fret {
            width:100%;
            height:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right:3px solid var(--col-guitar-fret);
            position: relative;
        }



        #{$self}__note {
            display: flex; 
            align-items: center;
            justify-content: center;
            width:50px;
            height:50px;
            border-radius: 50px;
            outline:4px solid var(--col-1);
            border:3px solid var(--col-guitar-note-border);
            font-weight: var(--fontweight-megabold);
            font-size: 1.25rem;
            background-color:var(--col-1); 
            cursor: pointer;

            &:hover {
                border-color:#fff;
            }

            &--active {
                background-color: var(--col-note-accent);
                border-color: var(--col-note-accent);
            
                &:hover {
                    border-color: var(--col-note-accent);
                }

            }

            &--matchinghover {
                border-color:#E4D00A !important;
                outline:2px solid #E4D00A !important;
                outline-offset: -1px;
            }

            &--sharpflat {
                font-size: 0.75rem;
               
            }

            &--root {
                border-color: var(--col-note-root);

                &:hover {
                    border-color: var(--col-note-root) !important;
                }
            }

        }

    }


}