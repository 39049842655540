@mixin roundbtn {
    --iconsize:20px;
    display:flex;
    align-items: center;
    border-radius: 100px;
    background-color:var(--col-3);
    font-weight:var(--fontweight-regular);
    font-size:var(--component-fontsize);
    height:var(--component-buttonheight);
    min-width: var(--component-buttonheight);
    padding:0px 16px; 
    gap:6px;
    line-height:0;
    outline:2px solid transparent;
    outline-offset: 1px;

    &:hover {
        outline-color:var(--col-accent);
        cursor: pointer;
    }

    &-lbl {
        display: block;
        line-height: var(--component-fontsize);
    }

    &-icon {
        fill:#fff;
        width:var(--iconsize);
        height:var(--iconsize);
        order:-1;
    }

} 

@mixin selectbox {
    @include roundbtn;
    line-height: 1rem;
    width:auto;
    text-align: center;
    width:140px;

}

@mixin filterswitch {
    display:flex;
    align-items: center;
    width:fit-content;
    gap:calc(var(--layout-gap) * 2);
    border-radius: 100px;
    background-color:transparent;
    font-weight:var(--fontweight-bold);
    font-size:var(--component-fontsize);
    height:var(--component-filterheight);
    padding:0 10px 0 20px; 
    line-height:0;
    border:1px solid var(--col-3);

    &__lbl {
        font-weight: var(--fontweight-regular);
        line-height: normal;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap:calc(var(--layout-gap) / 1.5);

    }

    &__btn {
        @include roundbtn;

        &:hover {
            background-color:var(--col-3--hover);
        }

        &.active {
            background-color: var( --col-accent);

            &:hover {
                 outline-color: var( --col-accent);
            }

        }

    }

    &__select {
        @include selectbox;
    }

}

