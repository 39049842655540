@use "./components" as *;

.main {
    padding:0px 2.5%;
    position: relative;
}

.scalefilter {
    @include filterswitch;

    &--disabled {
        pointer-events: none;
        opacity: 0.4;
    }

}

.betabutton {
    position:absolute;
    top:10px;
    right:50%;
    transform: translate(50%, 0%);
    z-index: 11;
    font-weight: var(--fontweight-bold);
    padding:8px 20px;
    border-radius: 30px;
    background-color:var(--col-comingsoon);

    &:hover {
        background-color: var(--col-accent);
        cursor: pointer;
    }

} 

